<template>
  <div class="transfer">
    <img src="@/assets/yq/transfer-loading.gif" />
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('user/RefreshToken').then(res=> {
        if(res.accessToken){
            setTimeout(() => {
              this.$router.replace({
                path: '/index',
              });
            }, 1000);
        }else {
            this.$store.dispatch('user/loginOut');
            setTimeout(() => {
                this.$router.replace({
                    path: '/login',
                });
            }, 1000);
        }
    })

    // if (getToken()) {
    //   //判断是否签署灵活协议
    //   this.$store.dispatch('user/checkNimbleAgreements')
    //     .then((res) => {
    //       if (res) {
    //         setTimeout(() => {
    //           this.$router.replace({
    //             path: '/index',
    //           });
    //         }, 1000);
    //         return;
    //       } else {
    //         setTimeout(() => {
    //           this.$router.replace({
    //             path: '/agileAgreement',
    //           });
    //         }, 1000);
    //         return;
    //       }
    //     })
    //     .catch((err) => {
    //       this.$toast({
    //         message: err,
    //       });
    //     });
    // } else {
    //   this.$store.dispatch('user/loginOut');
    //   setTimeout(() => {
    //     this.$router.replace({
    //       path: '/login',
    //     });
    //   }, 1000);
    // }
  },
};
</script>

<style scoped>
.transfer img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: 50px;
}
</style>
